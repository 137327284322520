import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import FloatingButton from "../commons/FloatingButton";

const Index = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Header active={pathname} />
      <Outlet />
      <Footer contact={pathname === "/contact"} />
      <FloatingButton />
    </>
  );
};

export default Index;
