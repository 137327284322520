import React from "react";
import PicTown from "../assets/images/pic-town.png";
import LogoBaktiKominfo from "../assets/images/logo-bakti-kominfo.svg";
import LogoBpk from "../assets/images/logo-bpk.svg";
import LogoBpkm from "../assets/images/logo-bpkm.svg";
import LogoKominfo from "../assets/images/logo-kominfo.svg";
import LogoKemenatr from "../assets/images/logo-kemenatr.svg";
import LogoKemenparekraf from "../assets/images/logo-kemenparekraf.svg";
import LogoKemnaker from "../assets/images/logo-kemnaker.svg";
import LogoPerpusnas from "../assets/images/logo-perpusnas.svg";
import LogoImigrasi from "../assets/images/logo-imigrasi.svg";
import PicStock from "../assets/images/pic-stock.png";
import PicStockFull from "../assets/images/pic-stock-full.png";
import Icon1 from "../assets/images/icon-1.svg";
import Icon2 from "../assets/images/icon-2.svg";
import Icon3 from "../assets/images/icon-3.svg";
import LogoSynxchro from "../assets/images/logo-synxchro.svg";
import LogoGiganet from "../assets/images/logo-giganet.svg";
import LogoEcatalogue from "../assets/images/logo-ecatalogue.svg";
import LogoCisco from "../assets/images/logo-cisco.svg";
import LogoIbm from "../assets/images/logo-ibm.svg";
import LogoNutanix from "../assets/images/logo-nutanix.svg";
import LogoSynxchroBlk from "../assets/images/logo-synxchro-blk.svg";
import LogoTableauBlk from "../assets/images/logo-tableau-blk.svg";
import LogoPalo from "../assets/images/logo-palo.svg";
import SideGrid from "../components/home/SideGrid";
import BoxItem from "../components/home/BoxItem";
import CardServices from "../components/home/CardServices";
import CardValues from "../components/home/CardValues";
import IconMedal from "../assets/images/icon-medal.svg";
import IconStar from "../assets/images/icon-star.svg";
import IconSpeaker from "../assets/images/icon-speaker.svg";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";

// bagi per warna background
const Index = () => {
  const ImgsGovernment = () => {
    const listGovernment = [
      { logo: LogoImigrasi, alt: "Imigrasi" },
      { logo: LogoKominfo, alt: "Kominfo" },
      { logo: LogoBpkm, alt: "BPKM" },
      { logo: LogoKemnaker, alt: "Kemnaker" },
      { logo: LogoBpk, alt: "BPK" },
      { logo: LogoPerpusnas, alt: "Perpusnas" },
      { logo: LogoBaktiKominfo, alt: "Bakti Kominfo" },
      { logo: LogoKemenatr, alt: "Kemenatr" },
      { logo: LogoKemenparekraf, alt: "Kemenparekraf" },
    ];
    return (
      <>
        {listGovernment.map((val, i) => {
          return <img src={val.logo} alt={val.alt} key={i} />;
        })}
      </>
    );
  };
  const listPartner = [{ logo: LogoPalo }, { logo: LogoIbm }, { logo: LogoCisco }, { logo: LogoNutanix }, { logo: LogoSynxchroBlk }, { logo: LogoTableauBlk }];
  return (
    <>
      <div className="bg-bg-primary py-5 px-5 lg:px-20 text-white font-font-primary overflow-x-hidden">
        <div className="flex">
          <div className="md:basis-1/2 flex flex-col justify-center gap-5">
            <h1 className="font-bold text-3xl text-center md:text-left">MULTIDATA</h1>
            <h2 className="font-extrabold text-6xl px-10 md:px-0 text-center md:text-left">Quality ICT Solution</h2>
            <div>
              <p className="text-xl md:pr-16 text-center md:text-left">
                Business venture that began in 1996 in Jakarta, which is focus in Information and Communication Technology (ICT) solution and services.{" "}
              </p>
            </div>
            <div className="flex justify-center md:justify-start">
              <Link to={"services"}>
                <button className="bg-white text-black font-bold px-6 py-2 rounded-full hover:bg-opacity-90">Check Our Products</button>
              </Link>
            </div>
          </div>
          <div className="hidden md:basis-1/2 md:flex justify-end">
            <img src={PicTown} alt="Pic Town" />
          </div>
        </div>
        <div className="mt-10">
          <p className="font-thin text-sm font-font-secondary mb-6 text-center md:text-left">Trusted by lots of Indonesian Government Agencies</p>
          <div className="hidden mmd:flex gap-10">
            <ImgsGovernment />
          </div>
          <div className="mmd:hidden">
            <Marquee className="">
              <div className="flex gap-5 justify-around px-2 h-10">
                <ImgsGovernment />
              </div>
            </Marquee>
          </div>
        </div>
        <div className="flex flex-col text-center my-28 lg:px-64 gap-8">
          <h2 className="font-extrabold text-4xl md:px-10">Most integrated system for your ICT solution!</h2>
          <p className="text-xl">
            We are providing Sistem Integration Solution (hardware, software, networking) and Professional Services. Also providing internet services with holding Certificate of National Internet Service
            Provider (ISP) License and Network Access Provider (NAP) License, since 2002.
          </p>
          <div>
            <Link to={"portofolio"}>
              <button className="bg-white text-black font-bold px-6 py-3 rounded-full hover:bg-opacity-90">See Business Portofolio</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-bg-primary text-white pl-5 pr-5 md:pl-20 md:pr-0 flex gap-10 flex-col md:flex-row md:justify-between">
        <div className="flex flex-col basis-2/5 gap-5">
          <SideGrid icon={Icon1} title={"System Integration (SI) & Professional Services"} description={"Provide Hardware, Sofware, Networking and Professional Services"} />
          <SideGrid icon={Icon2} title={"Network Access Provider (NAP)"} description={"Provide International Internet access capacity directly to Indonesia Internet Exchange gateway (IIX) as IP Transit"} />
          <SideGrid icon={Icon3} title={"Internet Service Provider (ISP)"} description={"Provide Internet dedicated access and Internet services"} />
        </div>
        <div className="hidden md:block basis-3/5 flex justify-end">
          <img src={PicStock} alt="Stock" />
        </div>
        <div className="md:hidden basis-3/5 flex justify-center">
          <img src={PicStockFull} alt="Stock" className="w-full" />
        </div>
      </div>
      <div className="bg-bg-primary px-5 lg:px-20 text-white font-font-primary">
        <div className="py-32 flex flex-col items-center gap-16">
          <h2 className="font-extrabold text-4xl md:w-1/3 text-center">Numbers speak of Client's Trust</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 w-full">
            <BoxItem title={"+12"} description={"System Integration & Professional Services Clients"} />
            <BoxItem title={"+115"} description={"Network Access Provider Clients"} />
            <BoxItem title={"+21"} description={"Experience with Certificate of National ISP & NAP Service"} unit={"years"} />
            <BoxItem title={"+15"} description={"Internet Service Provider Clients"} />
            <BoxItem title={"+15"} description={"System Integration Biz Partners"} />
          </div>
        </div>
      </div>
      <div className="px-5 lg:px-20 bg-bg-secondary overflow-x-hidden">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 my-10">
          <CardServices title={"Synxchro : Any data to any target"} img={LogoSynxchro} description={"Solutions for Enterprise Data Consolidation, Integration & Distribution (Simple, Secure, Scalable)."} />
          <CardServices
            title={"GigaNet: Quality Internet Solution"}
            img={LogoGiganet}
            description={"Solution for Internet bandwidth and all internet related services (dedicated link, IP Transit, FO and Wireless connection)"}
          />
          <CardServices
            title={
              <p>
                LKPP <br /> e-Catalogue
              </p>
            }
            img={LogoEcatalogue}
            description={"Products and services are registered at LKPP ecatalogue, makes it easy for the procurement process"}
          />
        </div>
        <div className="flex flex-col items-center my-32">
          <h2 className="font-bold text-4xl text-center">Our Value Proposition</h2>
          <p className="text-center text-thin font-font-secondary mt-5">
            We committed to give our best for your <br /> problem solving
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
            <CardValues description={"Provides best ICT solution from best of breed technologies"} icon={IconMedal} />
            <CardValues description={"Efficient and reliable internet solutions including price performance, SLA 99.5%, and 24 x 7 excellent support"} icon={IconStar} />
            <CardValues description={"Free consulting of the best implementation design of network infrastructure to solve network-related problems."} icon={IconSpeaker} />
          </div>
        </div>
      </div>
      <div className="bg-bg-secondary my-10 overflow-x-hidden">
        <p className="text-center font-font-secondary">We've been partnering with top global system integration biz companies</p>
        <Marquee speed={80}>
          <div className="flex gap-5 justify-around my-14 px-2 h-10">
            {listPartner.map((val, i) => {
              return <img src={val.logo} alt={i} key={i} />;
            })}
          </div>
        </Marquee>
      </div>
    </>
  );
};

export default Index;
