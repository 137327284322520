import React from "react";

const Headline = ({ title, children, mid = false }) => {
  return (
    <div className="bg-none sm:bg-head-texture bg-fix lg:bg-50% px-5 lg:px-20 pt-5 sm:pt-20 pb-10 sm:pb-20 bg-bg-primary bg-no-repeat bg-right-top-5 text-white flex flex-col gap-10">
      <h1 className={`text-4xl sm:text-5xl font-bold  ${mid ? "text-center" : "text-center sm:text-left"}`}>{title}</h1>
      {children}
    </div>
  );
};

export default Headline;
