import React from "react";

const NotFoundPage = () => {
  return (
    <div className="h-72 flex justify-center items-center">
      <h1 className="text-center font-bold text-4xl ">
        404 <br />
        Page not found
      </h1>
    </div>
  );
};

export default NotFoundPage;
