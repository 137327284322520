import React from "react";
import { Button } from "../forms/Button";

const ToContactFooter = () => {
  return (
    <div className="m-10">
      <h2 className="text-center text-4xl sm:text-5xl font-bold">Have a project in mind?</h2>
      <div className="flex justify-center mt-5 ">
        <p className="text-center text-md sm:text-xl font-font-secondary sm:w-1/2">Let's talk about your project and start the collaborations!</p>
      </div>
      <div className="flex flex-col items-center my-10">
        <Button text={"Let's Talk"} href={"tel:0215279901"} />
      </div>
    </div>
  );
};

export default ToContactFooter;
