import React from "react";
import iconWhatsapp from "../../assets/images/icon-wa.svg";

const FloatingButton = () => {
  return (
    <a href="https://wa.me/62818999555?text=Hallo Admin, saya mendapat info kontak dari Web Multidata. Saya tertarik untuk diskusi lebih lanjut terkait Layanan Multidata.">
      <button title="Contact Us" className="fixed bottom-10 right-5 sm:right-10 bg-bg-yellow rounded-full h-16 w-16 hover:scale-110 flex justify-center items-center shadow-lg">
        <img src={iconWhatsapp} alt="Whatsapp" className="h-8" />
      </button>
    </a>
  );
};

export default FloatingButton;
