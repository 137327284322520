import React, { useState } from "react";
import MainLogo from "../../assets/images/main-logo.svg";
import { Link } from "react-router-dom";
import IconMultidata from "../../assets/images/icon-multidata.svg";

const Header = ({ active }) => {
  const [showMenu, setShowMenu] = useState(false);
  const listHeader = [
    { to: "/", value: "Home" },
    { to: "/services", value: "Services" },
    { to: "/portofolio", value: "Portofolio" },
    { to: "/about", value: "About" },
  ];

  const onMenuClick = () => {
    setShowMenu((prev) => !prev);
  };
  return (
    <header className="md:flex md:justify-between bg-bg-primary py-5 px-5 lg:px-20 text-white font-font-primary overflow-x-hidden">
      <div className="hidden md:flex basis-2/3 items-center">
        <img src={MainLogo} alt="icon" />
        <nav className="grid grid-cols-4 gap-2 w-3/4 pl-10">
          {listHeader.map((val, i) => {
            return (
              <Link key={i} to={val.to} className={`hover:font-bold hover:bg-white hover:bg-opacity-30 py-2 px-5 rounded-xl text-center ${active === val.to ? "font-bold" : ""}`}>
                {val.value}
              </Link>
            );
          })}
        </nav>
      </div>
      <div className={`hidden md:flex justify-end gap-10 items-center basis-1/3`}>
        <Link
          to={"contact"}
          className={`text-center outline outline-2 outline-white rounded-full py-2 text-bold w-32 hover:bg-white hover:bg-opacity-30 hover:font-bold ${active === "/contact" ? "font-bold" : ""}`}
        >
          Contact Us
        </Link>
      </div>
      <div className="md:hidden">
        <button onClick={onMenuClick} className="hover:bg-white hover:bg-opacity-30 p-2 outline outline-2 outline-white rounded-xl">
          <img src={IconMultidata} alt="Icon Multidata" />
        </button>
      </div>
      <div className={`md:hidden mt-2 ${showMenu ? "" : "hidden"}`}>
        <ul className="flex flex-col gap-1">
          {listHeader.map((val, i) => {
            return (
              <Link to={val.to} onClick={onMenuClick} key={i} className="text-center hover:bg-white hover:bg-opacity-30 rounded-lg hover:font-bold py-1">
                {val.value}
              </Link>
            );
          })}
          <Link to={"contact"} onClick={onMenuClick} className="text-center hover:bg-white hover:bg-opacity-30 rounded-lg hover:font-bold py-1">
            Contact
          </Link>
        </ul>
      </div>
    </header>
  );
};

export default Header;
