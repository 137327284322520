import React from "react";

const Input = ({ label, message, id, placeholder, set, val }) => {
  return (
    <div className="w-full">
      <label htmlFor={id}>{label}*</label>
      <input id={id} type="text" className={`w-full rounded-lg p-2 ${message ? "h-32 align-top" : "h-12"} text-black`} placeholder={placeholder} onChange={(e) => set(e.target.value)} value={val} />
    </div>
  );
};

export default Input;
