import React from "react";
import MainLogo from "../../assets/images/main-logo.svg";
import GridFooter from "./GridFooter";
import ContactForm from "../contact/ContactForm";
import ToContactFooter from "./ToContactFooter";

const Footer = ({ contact = false }) => {
  return (
    <div className="bg-footer-texture py-10 px-5 lg:px-20 text-white font-font-primary bg-bg-primary bg-no-repeat bg-center">
      {contact ? <ContactForm /> : <ToContactFooter />}
      <hr className="opacity-30" />
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 mt-10">
        <GridFooter
          title={
            <div className="flex justify-center sm:justify-start">
              <img src={MainLogo} alt="Logo" className="w-[480px] sm:w-auto" />
            </div>
          }
          description={
            "Business venture that began in 1996 in Jakarta, which is focus in Information and Communication Technology (ICT) solution and services. Providing Sistem Integration Solution (hardware, software, networking) and Professional Services. Also providing internet services with holding Certificate of National Internet Service Provider (ISP) License and Network Access Provider (NAP) License, since 2002."
          }
        />
        <GridFooter
          title={"Our Office"}
          description={
            <p>
              Jl. Terusan Kuningan
              <br />
              H.R Rasuna Said No. 21 kuningan Barat.
              <br />
              Jakarta Selatan 12710.
            </p>
          }
        />
        <GridFooter
          title={"Contact Us"}
          description={
            <p>
              +62 21-527-9901 <br />
              info@multidata.co.id
            </p>
          }
        />
      </div>
    </div>
  );
};

export default Footer;
