import React from "react";

const CardServices = ({ img, title, description, href }) => {
  return (
    <div className="rounded-lg shadow-xl flex flex-col items-center px-5 py-10 gap-5 min-h-[30rem]">
      <div className="rounded-lg outline outline-[1px] outline-neutral-700/30 w-full flex justify-center p-5">
        <img src={img} alt={title} />
      </div>
      <div className="text-2xl font-bold w-full">{title}</div>
      <div>{description}</div>
      <div className="flex items-end w-full h-full justify-start">
        <button className="bg-bg-yellow rounded-full font-bold px-5 py-2">
          <a href={href}>Learn More</a>
        </button>
      </div>
    </div>
  );
};

export default CardServices;
