import React from "react";

const CardContact = ({ title, href, children, btnText }) => {
  return (
    <div className="bg-white bg-opacity-30 rounded-lg flex flex-col justify-between h-72 p-5">
      <div className="flex flex-col gap-5">
        <h3 className="font-bold text-3xl">{title}</h3>
        {children}
      </div>
      <div className={btnText ? "" : "hidden"}>
        <button className="bg-white text-black font-bold rounded-full py-2 px-5 hover:bg-opacity-90">
          <a href={href}>{btnText}</a>
        </button>
      </div>
    </div>
  );
};

export default CardContact;
