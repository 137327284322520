import React from "react";

const PortofolioSection = ({ img, title, description }) => {
  return (
    <div className="text-center flex flex-col py-10 gap-10 justify-center border-b-2 last:border-b-0">
      <h2 className="text-4xl font-bold">{title}</h2>
      {description ? <p className="text-thin font-font-secondary">{description}</p> : null}
      <div className="w-full flex justify-center">
        <img src={img} alt={title} className="w-fit" />
      </div>
    </div>
  );
};

export default PortofolioSection;
