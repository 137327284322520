import React from "react";

const CardValues = ({ icon, description }) => {
  return (
    <div className="rounded-lg shadow-xl flex flex-col items-center mt-10 px-16 pt-10 pb-16 gap-5">
      <div className="rounded-lg w-full flex justify-center p-5">
        <img src={icon} alt={description} />
      </div>
      <div className="text-thin font-font-secondary text-center">{description}</div>
    </div>
  );
};

export default CardValues;
