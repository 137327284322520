import React from "react";

const GridFooter = ({ title, description }) => {
  return (
    <div className="">
      <div className="text-bold text-xl">{title}</div>
      <div className="font-font-secondary text-thin mt-5">{description}</div>
    </div>
  );
};

export default GridFooter;
