import React from "react";

const SideGrid = ({ icon, title, description }) => {
  return (
    <div className="flex gap-2">
      <div className="basis-1/5">
        <img src={icon} alt={title} />
      </div>
      <div className="basis-4/5">
        <h3 className="font-bold text-xl">{title}</h3>
        <p className="">{description}</p>
      </div>
    </div>
  );
};

export default SideGrid;
