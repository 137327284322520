import React from "react";
import Headline from "../components/commons/Headline";
import CardContact from "../components/contact/CardContact";

const Contact = () => {
  return (
    <>
      <Headline title={"Contact"}>
        <p className="text-center sm:text-left">Pleased to get in touch with you!</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
          <CardContact title={"Location"} btnText={"View on Maps"} href={"https://goo.gl/maps/kNPbk1YfKRReqtEc7"}>
            <p>
              Jl. Terusan Kuningan
              <br />
              H.R Rasuna Said No. 21 kuningan Barat.
              <br />
              Jakarta Selatan 12710.
            </p>
          </CardContact>
          <CardContact title={"Email"} btnText={"Contact Now"} href={"mailto:sales@multidata.co.id"}>
            <p>info@multidata.co.id</p>
          </CardContact>
          <CardContact title={"Social Media"}>
            <ul>
              <li>Facebook</li>
              <li>Twitter</li>
              <li>LinkedIn</li>
              <li>Instagram</li>
            </ul>
          </CardContact>
        </div>
      </Headline>
      <div className="h-80">
        <iframe
          title="GMaps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.3348468950617!2d106.829066474315!3d-6.219500160914199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3f7a70c7449%3A0x26c834ba32e6bd10!2sJl.%20Terusan%20Kuningan%20Jl.%20H.%20R.%20Rasuna%20Said%20No.21%2C%20Karet%20Kuningan%2C%20Kecamatan%20Setiabudi%2C%20Kota%20Jakarta%20Selatan%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2012940!5e0!3m2!1sid!2sid!4v1692550410401!5m2!1sid!2sid"
          className="w-full h-full"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default Contact;
