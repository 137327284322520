import React from "react";
import Headline from "../components/commons/Headline";
import MainLogoBig from "../assets/images/main-logo-big.png";
import PicMap from "../assets/images/pic-map.png";
import PicIspLicense from "../assets/images/pic-isp-license.png";
import PicNapLicense from "../assets/images/pic-nap-license.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const About = () => {
  const missonList = [
    {
      element: "Provide continous expansion in information technology services for the e-Economy.",
    },
    {
      element: "Provide a best of breed with cost- effectivity IT solution.",
    },
    {
      element: "Provide quality service to all our customers with win-win situations.",
    },
    {
      element: "Develop close relationship with all principals and business partners",
    },
  ];
  return (
    <>
      <Headline title={"Who are we?"} mid={true}>
        <div className="grid grid-cols-1 lg:grid-cols-2 px-5 max-w-[1280px] gap-10 py-10">
          <div className="flex justify-center lg:justify-start">
            <img src={MainLogoBig} alt="Logo" className="" />
          </div>
          <div className="flex flex-col gap-5 text-center lg:text-left">
            <h2 className="text-3xl font-bold">PT Multidata Rancana Prima</h2>
            <p>
              Business venture that began in 1996 in Jakarta, which is focus in Information and Communication Technology (ICT) solution and services. Providing Sistem Integration Solution (hardware, software,
              networking) and Professional Services. Also providing internet services with holding Certificate of National Internet Service Provider (ISP) License and Network Access Provider (NAP) License,
              since 2002.
            </p>
          </div>
        </div>
      </Headline>
      <div className="bg-bg-secondary px-5 lg:px-20 py-20">
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:px-20 gap-10">
          <div className="flex justify-center">
            <img src={PicMap} alt="Map" className="max-h-[512px]" />
          </div>
          <div className="flex flex-col justify-between gap-10">
            <div className="flex flex-col gap-5 text-center lg:text-left">
              <h3 className="text-3xl font-bold">Vision</h3>
              <p>Trusted partner providing Information Communication Technology (ICT) solution through improvement of Commitment, Competency and Consistency.</p>
            </div>
            <div className="flex flex-col gap-5">
              <h3 className="text-3xl font-bold text-center lg:text-left">Mission</h3>
              <ul className="flex flex-col gap-2 sm:mx-24 md:mx-52 lg:mx-0">
                {missonList.map((val, i) => {
                  return (
                    <li className="flex gap-5" key={i}>
                      <FontAwesomeIcon icon={faCheckCircle} className="mt-1 text-bg-primary" size="lg" />
                      <p>{val.element}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-32">
          <h2 className="mb-10 text-center text-4xl font-bold">ISP & NAP License</h2>
          <div className="flex justify-center items-center lg:items-start flex-col lg:flex-row gap-20">
            <img src={PicIspLicense} alt="ISP License" className="max-w-[320] md:max-w-[480px]" />
            <img src={PicNapLicense} alt="NAP License" className="max-w-[320] md:max-w-[480px]" />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
