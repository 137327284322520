import React from "react";
import Headline from "../components/commons/Headline";
import PicSystemBiz from "../assets/images/pic-system-biz.png";
import PicIsp from "../assets/images/pic-isp.png";
import PicNap from "../assets/images/pic-nap.png";
import PicTopology from "../assets/images/pic-topology.png";
import PortofolioSection from "../components/portofolio/PortofolioSection";

const Portofolio = () => {
  const sectionList = [
    { img: PicSystemBiz, title: "System Integration Biz", description: "Solutions for Enterprise Data Consolidation, Integration & Distribution(Simple, Secure, Scalable)." },
    { img: PicIsp, title: "Internet Service Provider (ISP)", description: "Solutions for Enterprise Data Consolidation, Integration & Distribution(Simple, Secure, Scalable)." },
    { img: PicNap, title: "Network Access Provider (NAP)", description: "Solutions for Enterprise Data Consolidation, Integration & Distribution(Simple, Secure, Scalable)." },
    { img: PicTopology, title: "Network Topology" },
  ];
  return (
    <>
      <Headline title={"Portofolio"}>
        <p className="text-center sm:text-left sm:max-w-[50%]">We've been collaborating with Indonesian Government Agencies and partnering with many big system integration companies.</p>
      </Headline>
      <div className="px-5 lg:px-20 py-10">
        {sectionList.map((val, i) => {
          return <PortofolioSection key={i} img={val.img} title={val.title} description={val?.description} />;
        })}
      </div>
    </>
  );
};

export default Portofolio;
