import React from "react";

const TextArea = ({ label, id, placeholder, set, val }) => {
  return (
    <div>
      <label htmlFor={id}>{label}*</label>
      <textarea id={id} className="w-full rounded-lg p-2 h-32 text-black" placeholder={placeholder} onChange={(e) => set(e.target.value)} value={val} />
    </div>
  );
};

export default TextArea;
