import React from "react";
import CardServices from "../components/home/CardServices";
import LogoSynxchro from "../assets/images/logo-synxchro.svg";
import LogoGiganet from "../assets/images/logo-giganet.svg";
import LogoEcatalogue from "../assets/images/logo-ecatalogue.svg";
import LogoEfiskus from "../assets/images/logo-efiskus.svg";
import LogoTabelau from "../assets/images/logo-tabelau.svg";
import Headline from "../components/commons/Headline";

const Services = () => {
  return (
    <>
      <Headline title={"Best ICT Services"}>
        <p className="text-center sm:text-left">We provide the best ICT Services in class for your company.</p>
      </Headline>
      <div className="bg-bg-secondary px-5 lg:px-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 my-10">
        <CardServices title={"Synxchro : Any data to any target"} img={LogoSynxchro} description={"Solutions for Enterprise Data Consolidation, Integration & Distribution (Simple, Secure, Scalable)."} />
        <CardServices
          title={"GigaNet: Quality Internet Solution"}
          img={LogoGiganet}
          description={"Solution for Internet bandwidth and all internet related services (dedicated link, IP Transit, FO and Wireless connection)"}
        />
        <CardServices
          title={
            <p>
              LKPP <br /> e-Catalogue
            </p>
          }
          img={LogoEcatalogue}
          description={"Products and services are registered at LKPP ecatalogue, makes it easy for the procurement process"}
        />
        <CardServices title={"e-Fiskus"} img={LogoEfiskus} description={"Solutions for Optimization Region Tax Revenue (PB1)"} />
        <CardServices title={"Tableau"} img={LogoTabelau} description={"Solutions for Business Intelligence/Company Development Dashboards"} />
      </div>
    </>
  );
};

export default Services;
