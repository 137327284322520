import React from "react";

const BoxItem = ({ title, unit, description }) => {
  return (
    <div className="rounded bg-white bg-opacity-20 text-center h-48 flex flex-col justify-center py-16 px-10 gap-5">
      <div className="text-5xl font-bold">
        {title}
        {unit ? <small> {unit}</small> : ""}
      </div>
      <div className="font-thin font-font-secondary">{description}</div>
    </div>
  );
};

export default BoxItem;
