import React, { useState } from "react";
import Input from "../forms/Input";
import TextArea from "../forms/TextArea";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

const ContactForm = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const isValid = fname !== "" && lname !== "" && email !== "" && message !== "";

  const fetchAuth = fetch(`${API_URL}/v1/auth/login-app`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: process.env.REACT_APP_USERNAME_AUTH,
      password: process.env.REACT_APP_PASSWORD_AUTH,
    }),
  });

  const sendEmail = (token) =>
    fetch(`${API_URL}/v1/email/send`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        fullname: `${fname} ${lname}`,
        from: email,
        to: "info@multidata.co.id",
        message: message,
        subject: "Let's Talk More About Your ICT Solution",
      }),
    });

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    fetchAuth
      .then((res) => res.json())
      .then((res) => {
        const token = res.access_token;
        sendEmail(token)
          .then((res) => res.json())
          .then(() => {
            Swal.fire({
              title: "Success",
              text: "E-mail berhasil dikirim.",
              icon: "success",
              confirmButtonText: "Continue",
              confirmButtonColor: "#2353F5",
            });
            setLoading(false);
            setFname("");
            setLname("");
            setEmail("");
            setMessage("");
          });
      })
      .catch(() => {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Continue",
          confirmButtonColor: "#2353F5",
        });
      });
  };
  return (
    <div className="my-10 grid grid-cols-1 md:grid-cols-2 gap-10">
      <h2 className="text-5xl font-bold text-center md:text-left">Send us a message and we will respond as quickly as possible</h2>
      <form className="w-full flex flex-col gap-5" onSubmit={(e) => onSubmitForm(e)}>
        <div className="flex flex-col sm:flex-row gap-5 sm:gap-10 w-full">
          <Input label={"First Name"} placeholder={"John"} set={setFname} val={fname} />
          <Input label={"Last Name"} placeholder={"Doe"} set={setLname} val={lname} />
        </div>
        <Input label={"Email"} placeholder={"ex: john.doe@gmail.com"} set={setEmail} val={email} />
        <TextArea label={"Message"} placeholder={"Type your message here"} set={setMessage} val={message} />
        <div className="flex justify-center sm:justify-end">
          <button type="submit" className={`bg-bg-yellow text-black text-xl font-bold rounded-full px-16 py-4 hover:bg-opacity-90 disabled:bg-opacity-80"`} disabled={loading || !isValid}>
            {loading ? <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> : "Let's Talk"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
